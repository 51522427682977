// node modules
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// components
import Hero from 'components/pages/category-page/hero'
import Breadcrumbs from 'components/shared/breadcrumbs'
import Intro from 'components/pages/category-page/intro'
import Products from 'components/pages/category-page/products'
import Infobox from 'components/pages/category-page/infobox'
import About from 'components/pages/category-page/about'
import Contact from 'components/pages/category-page/contact'

// types
import { ProductThumbnail } from 'types/product'

const IndexPage: React.FC<PageProps<Queries.MotocyklePageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.motocycle
  const PAGE_SEO = data.wpPage?.seo!
  const PRODUCTS = data.allWpVehiclesArticle.nodes

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const MOTORCYCLES_VEHICLES: ProductThumbnail[] =
    PRODUCTS?.filter(
      (product) =>
        product?.produkt?.productOptionsProductName &&
        product?.produkt?.productOptionsProductImage
    )
      ?.map(({ date, slug, produkt }) => ({
        image: {
          src: produkt?.productOptionsProductImage?.localFile?.childImageSharp!
            ?.gatsbyImageData,
          alt: produkt?.productOptionsProductImage?.altText!,
        },
        name: produkt?.productOptionsProductName!,
        priceFrom: produkt?.productOptionsPriceFrom!,
        noPrice: Boolean(produkt?.productOptionsNoPrice),
        category: produkt?.productOptionsProductType!,
        dateAdded: date!,
        slug: slug!,
      }))
      .sort(
        (a, b) =>
          new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()
      ) || []

  const ABOUT_SECTIONS = [
    {
      image: {
        src: PAGE?.motocycleAdvImg1?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: PAGE?.motocycleAdvImg1?.altText!,
      },
      title: PAGE?.motocycleAdvSubtitle1!,
      description: PAGE?.motocycleAdvDescription1!,
    },
    {
      image: {
        src: PAGE?.motocycleAdvImg2?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: PAGE?.motocycleAdvImg2?.altText!,
      },
      title: PAGE?.motocycleAdvSubtitle2!,
      description: PAGE?.motocycleAdvDescription2!,
    },
    {
      image: {
        src: PAGE?.motocycleAdvImg3?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: PAGE?.motocycleAdvImg3?.altText!,
      },
      title: PAGE?.motocycleAdvSubtitle3!,
      description: PAGE?.motocycleAdvDescription3!,
    },
  ]

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Hero
        heading={PAGE?.motocycleBannerTitle!}
        image={{
          src: PAGE?.motocycleBannerHeroImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.motocycleBannerHeroImg?.altText!,
        }}
      />
      <Breadcrumbs crumbs={[{ name: data?.wpPage?.title! }]} />
      <Intro
        heading={PAGE?.motocycleDescriptionTitle!}
        text={PAGE?.motocycleDescriptionText!}
      />
      {MOTORCYCLES_VEHICLES.length > 0 && (
        <Products products={MOTORCYCLES_VEHICLES} />
      )}
      <Infobox
        heading={PAGE?.motocycleSeparatorText!}
        image={{
          src: PAGE?.motocycleSeparatorImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.motocycleSeparatorImg?.altText!,
        }}
      />
      {ABOUT_SECTIONS.length > 0 && (
        <About heading={PAGE?.motocycleAdvTitle!} sections={ABOUT_SECTIONS} />
      )}
      <Contact
        heading={PAGE?.motocycleContactTitle!}
        text="Skontaktuj się z nami"
        phone={PAGE?.motocycleContactPhoneNumber!}
        email={PAGE?.motocycleContactEmail!}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query MotocyklePage {
    wpPage(slug: { regex: "/motocykle/" }) {
      title
      motocycle {
        motocycleBannerTitle
        motocycleDescriptionTitle
        motocycleDescriptionText
        motocycleSeparatorText
        motocycleAdvTitle
        motocycleAdvSubtitle1
        motocycleAdvSubtitle2
        motocycleAdvSubtitle3
        motocycleAdvDescription1
        motocycleAdvDescription2
        motocycleAdvDescription3
        motocycleContactTitle
        motocycleContactEmail
        motocycleContactPhoneNumber
        motocycleBannerHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                breakpoints: [1280, 1920]
              )
            }
          }
        }
        motocycleSeparatorImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        motocycleAdvImg1 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1250)
            }
          }
        }
        motocycleAdvImg2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1250)
            }
          }
        }
        motocycleAdvImg3 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1250)
            }
          }
        }
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
    allWpVehiclesArticle(
      filter: { produkt: { productOptionsProductType: { eq: "Motocykl" } } }
    ) {
      nodes {
        date
        slug
        produkt {
          productOptionsProductType
          productOptionsProductName
          productOptionsPriceFrom
          productOptionsNoPrice
          productOptionsProductImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700)
              }
            }
          }
        }
      }
    }
  }
`
